<template>
  <div>
    <div style="margin: 20px 0 0px 20px">
      <MyBreadcrumb></MyBreadcrumb>
    </div>
    <div class="project-list-main">
      <div class="margin-bottom-10 top">
        <Button type="primary" icon="md-add" @click="getCategoryList">
          {{$t('project_create_project')}}
        </Button>
        <div class="search_input">
          <Input v-model="keyword"
                 @on-enter="changeKeyword"
                 @on-search="changeKeyword"
                 :placeholder="$t('project_search_project_nameid')"
                 clearable
                 search
                 :enter-button="true"
          />
        </div>
      </div>
      <Row>
        <Table
            highlight-row
            ref="projectTable"
            :columns="tableOption"
            :data="tableData"
            :loading="loading"
            stripe
            border
            show-header
            @on-filter-change = "filterChange"
            @on-sort-change = "sortChange"
        ></Table>
        <div style="margin: 10px;overflow: hidden">
          <div style="float: right;">
            <Page
                :total="count"
                :current="page"
                :page-size ="limit"
                :page-size-opts="[5,10,15,20,25]"
                show-total
                show-elevator
                show-sizer
                placement = "top"
                transfer
                @on-change="changePage"
                @on-page-size-change = "changePageSize"
            ></Page>
          </div>
        </div>
      </Row>
      <Modal
          v-model="deleteComfirmModal"
          :title="$t('project_operation_tip')">
        <p>{{$t('project_sure_delete_current_project')}}</p>
        <div slot="footer">
          <Button type="text" @click="deleteComfirmModal = false">{{$t('project_cancel')}}</Button>
          <Button type="error" @click="deleteProject">{{$t('project_delete')}}</Button>
        </div>
      </Modal>
      <Modal
          v-model="reopenModel"
          :title="$t('project_operation_tip')">
        <p>{{$t('project_sure_restart_current_project')}}</p>
        <div slot="footer">
          <Button type="text" @click="reopenModel = false">{{$t('project_cancel')}}</Button>
          <Button type="success" @click="reopenProject">{{$t('project_restart')}}</Button>
        </div>
      </Modal>
      <Modal
          v-model="pauseModel"
          :title="$t('project_operation_tip')">
        <p>{{$t('project_sure_pause_current_project')}}</p>
        <div slot="footer">
          <Button type="text" @click="pauseModel = false">{{$t('project_cancel')}}</Button>
          <Button type="error" @click="pauseProject">{{$t('project_pause')}}</Button>
        </div>
      </Modal>
      <!--    <Modal-->
      <!--        v-model="recoverModel"-->
      <!--        :title="$t('project_operation_tip')">-->
      <!--      <p>{{$t('project_sure_recover_current_project')}}</p>-->
      <!--      <div slot="footer">-->
      <!--        <Button type="text" @click="recoverModel = false">{{$t('project_cancel')}}</Button>-->
      <!--        <Button type="success" @click="recoverProject">{{$t('project_recover')}}</Button>-->
      <!--      </div>-->
      <!--    </Modal>-->
      <Modal
          v-model="finishModel"
          :title="$t('project_operation_tip')">
        <p>{{$t('project_sure_completed_current_project')}}</p>
        <div slot="footer">
          <Button type="text" @click="finishModel = false">{{$t('project_cancel')}}</Button>
          <Button type="success" @click="finishProject">{{$t('project_sure')}}</Button>
        </div>
      </Modal>
      <!-- <Modal
          v-model="selectCategoryModal"
          :title="$t('project_select_type')"
          :footer-hide="true"
          :scrollable="true"
          class="select-category-modal"
          width="800px"
      >
        <div class="category-list">
          <div class="category-list-top">
            <div :class="curCategoryType == item.value ? 'active' : '' " v-for="item in categoryTypes" :key="item.value" @click="changeCategoryType(item.value)">{{item.name}}</div>
          </div>
          <div class="category-list-cont">
            <div v-for="item in categoryList" :key="item.id">
              <p class="tit">{{item.name}}通用</p>
              <div class="desc">
                根据固定格式的模版进行任务发布,包括表格字段名称和格式、sheet名称和格式。
              </div>
              <Button type="primary" class="btn" v-if="item.status == 0" @click="goCreateProject(item.id)">创建此类项目</Button>
            </div>

          </div>
        </div>
      </Modal> -->
    </div>
    <el-drawer :visible.sync="selectCategoryModal" :size="800">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ $t('project_select_type') }}</div>
        <div class="drawer-title-btns">
        </div>
      </div>
      <div style="padding: 20px;" class="select-category-modal">
        <div class="category-list">
          <div class="category-list-top">
            <div :class="curCategoryType == item.value ? 'active' : '' " v-for="item in categoryTypes" :key="item.value" @click="changeCategoryType(item.value)">{{item.name}}</div>
          </div>
          <div class="category-list-cont">
            <div v-for="item in categoryList" :key="item.id">
              <p class="tit">{{item.name}}通用</p>
              <div class="desc">
                根据固定格式的模版进行任务发布,包括表格字段名称和格式、sheet名称和格式。
              </div>
              <Button type="primary" class="btn" v-if="item.status == 0" @click="goCreateProject(item.id)">创建此类项目</Button>
            </div>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>

</template>

<script>
import api from '@/api';
import util from '@/utils/tools.js';
import ProjectOp from './components/project-op';
import unpackOp from './components/unpackOp';
import taskOp from './components/task-op';
import Vue from 'vue';

export default {
  name: 'project-management',
  data () {
    return {
      loading: false,
      count: 0,
      keyword: '',
      page: 1,
      limit: 10,
      status: '',
      orderby: '',
      sort: '',
      category_id: '',
      // statuses: [],
      statusList:{
        0:'发布中',
        1:'数据解析中',
        2:'作业中',
        3:'暂停',
        4:'已停止',
        5:'已完成',
        6:'删除',
      },
      tableOption: [
        {
          title: 'ID',
          key: 'id',
          align: 'center',
          width:80
        },
        {
          title: this.$t('project_project_name'),
          key: 'name',
          minWidth: 150,
          // ellipsis: true,
          render: (h, params) => {
            return h('router-link', {
              props: {
                to: {
                  name: 'project-detail',
                  params: {
                    id: params.row.id,
                    tab: 'info'
                  }
                }
              },
              style:{
                color:'#2db7f5',
              }
            }, params.row.name);
          }
        },
        {
          title: this.$t('project_list_type'),
          key: 'category_id',
          minWidth: 100,
          align: 'center',
          render: (h, para) => {
            console.log(para.row.category,'para.row.category.id')
            return h('span', para.row.category ? this.categories[para.row.category.id] : '');
          },
        },
        // {
        //   title: this.$t('project_publisher'),
        //   key: 'user_name',
        //   align: 'center',
        //   maxWidth: 140,
        //   render: (h, para) => {
        //     if (para.row.user){
        //     return h('div', [
        //       h('Tooltip', {
        //         props: {
        //           content: this.$t('project_nickname') + ': ' + para.row.user.nickname,
        //           placement: 'top',
        //           transfer: true,
        //         },
        //         scopedSlots: {
        //           content: () => {
        //             return h('span', {
        //             }, [
        //               h('div', 'ID: ' + para.row.user.id),
        //               h('div', this.$t('project_nickname') + ': ' + para.row.user.nickname),
        //             ]);
        //           }
        //         }
        //       }, [
        //         h('div', para.row.user.nickname),
        //         h('div', para.row.user.company_name)
        //       ]),
        //     ]);
        //     }
        //   }
        // },
        {
          title: this.$t('project_leader'),
          key: 'user_name',
          align: 'center',
          minWidth: 100,
          render: (h, para) => {
            if (para.row.leaderUser){
            return h('div', [
              h('Tooltip', {
                props: {
                  content: this.$t('project_nickname') + ': ' + para.row.leaderUser.nickname,
                  placement: 'top',
                  transfer: true,
                },
                scopedSlots: {
                  content: () => {
                    return h('span', {
                    }, [
                      h('div', 'ID: ' + para.row.leaderUser.id),
                      h('div', this.$t('project_nickname') + ': ' + para.row.leaderUser.nickname),
                    ]);
                  }
                }
              }, [
                h('div', para.row.leaderUser.nickname),
                h('div', para.row.leaderUser.company_name)
              ]),
            ]);
            }
          }
        },
        {
          title: this.$t('project_project_status'),
          key: 'status',
          align: 'left',
          minWidth: 150,
          render: (h, para) => {
            // return h('div', [
            //   h(unpackOp, {
            //     props: {
            //       status: para.row.status,
            //       statuses: this.statuses,
            //       row: para.row
            //     },
            //   })
            // ]);
            return h('div', {}, this.statusList[para.row.status]);
          },
        },
        {
          title: this.$t('project_batch_count'),
          align: 'center',
          minWidth: 100,
          render: (h, para) => {
            return h('span', para.row.batch_count > 0 ? para.row.batch_count : '-');
          },
        },
        {
          title: this.$t('project_data_count'),
          key: 'amount',
          minWidth: 100,
          align: 'center',
          render: (h, para) => {
            return h('span', para.row.amount > 0 ? para.row.amount : '-');
          },
        },
        {
          title: this.$t('project_join_user_count'),
          key: 'join_user_count',
          minWidth: 100,
          align: 'center',
          render: (h, para) => {
            return h('span', para.row.join_user_count > 0 ? para.row.join_user_count : '-');
          },
        },
        {
          title: this.$t('project_starting_time'),
          key: 'start_time',
          align: 'center',
          //maxWidth: 50,
          minWidth: 220,
          render: (h, para) => {
            return h(
                'span',[
                  util.timeFormatter(
                    new Date(+para.row.start_time * 1000),
                    'yyyy-MM-dd'
                ),' - ', util.timeFormatter(
                    new Date(+para.row.end_time * 1000),
                    'yyyy-MM-dd'
                )]
            );
          }
        },
        // {
        //   title: this.$t('project_finish_time'),
        //   key: 'end_time',
        //   align: 'center',
        //   minWidth: 60,
        //   render: (h, para) => {
        //     return h(
        //         'span',
        //         util.timeFormatter(
        //             new Date(+para.row.end_time * 1000),
        //             'yyyy-MM-dd'
        //         )
        //     );
        //   }
        // },
        {
          title: this.$t('project_operation'),
          align: 'left',
          key: 'status',
          width: 200,
          fixed:"right",
          render: (h, para) => {
            return h('div', [
              h(ProjectOp, {
                props: {
                  status: para.row.status,
                  projectId: para.row.id,
                  template_id: para.row.template_id,
                  release_status:para.row.release_status,
                },
                on: {
                  'delete-project': (projectId) => {  //删除
                    this.deleteComfirmModal = true;
                    this.wantDeleteProjectId = projectId;
                    console.log(projectId,'projectId')
                  },
                  'pause-project': (projectId) => { //暂停
                    this.pauseModel = true;
                    this.pauseProjectId = projectId;
                  },
                  'reopen-project': (projectId) => {  //重启
                    this.reopenModel = true;
                    this.reopenProjectId = projectId;
                  },
                  'finish-project': (projectId) => {  //完成
                    this.finishModel = true;
                    this.finishProjectId = projectId;
                  }
                }
              })
            ]);
          }
        }
      ],
      tableData: [],
      deleteComfirmModal: false,
      recoverModel: false,
      pauseModel: false,
      reopenModel: false,
      finishModel: false,
      wantDeleteProjectId: '',
      pauseProjectId: '',
      recoverProjectId: '',
      reopenProjectId: '',
      finishProjectId: '',
      selectCategoryModal: false,
      categories: [],
      categoryListAll:[],
      categoryList: [],
      categoryTypes:[],
      curCategoryType:'',
    };
  },
  watch: {
    keyword () {
      if (!this.keyword) {
        this.page = 1;
        this.getData();
      }
    }
  },
  mounted () {
    this.getData();
  },
  methods: {
    deleteProject () {
      let params = {
        project_id:this.wantDeleteProjectId,
      }
      this.api.production.projectDelete(params).then((res)=>{
        this.deleteComfirmModal = false;
        this.getData();
      })
      // $.ajax({
      //   url: api.project.delete,
      //   type: 'post',
      //   data: {
      //     access_token: this.$store.state.user.userInfo.accessToken,
      //     project_id: this.wantDeleteProjectId
      //   },
      //   success: res => {
      //     if (res.error) {
      //       this.$Message.warning({
      //         content: res.message,
      //         duration: 3
      //       });
      //     } else {
      //       this.$Message.success({
      //         content: this.$t('project_delete_success'),
      //         duration: 3
      //       });
      //       this.getData();
      //     }
      //     this.deleteComfirmModal = false;
      //   },
      //   error: (res, textStatus, responseText) => {
      //     util.handleAjaxError(this, res, textStatus, responseText, () => {
      //       this.deleteComfirmModal = false;
      //     });
      //   }
      // });
    },
    reopenProject () {
      let params = {
        project_id:this.reopenProjectId,
      }
      this.api.production.projectRecovery(params).then((res)=>{
        this.reopenModel = false;
        this.getData();
      })
    },
    pauseProject () {
      $.ajax({
        url: api.project.pause,
        type: 'post',
        data: {
          access_token: this.$store.state.user.userInfo.accessToken,
          project_id: this.pauseProjectId
        },
        success: res => {
          if (res.error) {
            this.$Message.warning({
              content: res.message,
              duration: 3
            });
          } else {
            this.$Message.success({
              content: this.$t('project_pauset_success'),
              duration: 3
            });
            this.getData();
          }
          this.pauseModel = false;
        },
        error: (res, textStatus, responseText) => {
          util.handleAjaxError(this, res, textStatus, responseText, () => {
            this.pauseModel = false;
          });
        }
      });
    },
    finishProject () {
      $.ajax({
        url: api.project.finish,
        type: 'post',
        data: {
          access_token: this.$store.state.user.userInfo.accessToken,
          project_id: this.finishProjectId
        },
        success: res => {
          if (res.error) {
            this.$Message.warning({
              content: res.message,
              duration: 3
            });
          } else {
            this.$Message.success({
              content: this.$t('project_project_setup'),
              duration: 3
            });
            this.getData();
          }
          this.finishModel = false;
        },
        error: (res, textStatus, responseText) => {
          util.handleAjaxError(this, res, textStatus, responseText, () => {
            this.finishModel = false;
          });
        }
      });
    },
    changePage (page) {
      this.page = page;
      this.getData();
    },
    changePageSize (size) {
      this.limit = size;
      this.getData();
    },
    getTableData (data) {
      this.tableData = data.list;
      this.count = +data.count; // 整数
      // let categoryMap = [];
      // let statusesMap = [];
      // this.statuses = data.statuses;
      // Object.keys(data.categories).forEach(v => {
      //   let category = {
      //     label: data.categories[v],
      //     value: v
      //   };
      //   categoryMap.push(category);
      // });
      // Object.keys(data.statuses).forEach(v => {
      //   let status = {
      //     label: data.statuses[v],
      //     value: v
      //   };
      //   statusesMap.push(status);
      // });

    },
    changeKeyword () {
      this.page = 1;
      this.getData();
    },
    filterChange (filter) {
      let key = filter.key;
      this[key] = filter._filterChecked.toString();
      this.page = 1;
      this.getData();
    },
    sortChange ({ column, key, order }) {
      this.orderby = key;
      this.sort = order;
      this.page = 1;
      this.getData();
    },
    getData () {
      this.loading = true;

      let data = {
        page: this.page,
        pageSize:this.limit,
        keyword: this.keyword,
      };
      this.api.production.projects(data).then((res)=>{
        this.loading = false;
        this.categories = res.categories || [];
        if(!res.list.length && this.page > 1){
          this.page--;
          this.getData();
        }else{
          this.getTableData(res);
        }

      }).catch((err)=>{
        this.loading = false;
      })
    },
    getCategoryList () {
      this.api.production.projectForm().then((res)=>{
        this.categoryListAll = res.categories || [];
        this.selectCategoryModal = true;

        this.categoryTypes = [];
        for(let name in res.categoryTypes){
          this.categoryTypes.push({
            name:res.categoryTypes[name],
            value:name,
          })
        }
        this.curCategoryType = this.categoryTypes[0].value;


        this.categoryList = this.categoryListAll.filter((item)=>{
          return item.type == this.curCategoryType;
        });
      })
    },
    changeCategoryType(data){
      this.curCategoryType = data;

      this.categoryList = this.categoryListAll.filter((item)=>{
        return item.type == this.curCategoryType;
      });
    },
    goCreateProject (categoryId) {  //选择分类-创建项目
      let data = {
        op:'create',
        category_id:categoryId,
      };
      this.api.production.publish(data).then((res)=>{
        let data = res;
        this.$Message.success({
          content: this.$t('project_project_created_successfully'),
          duration: 3
        });
        this.$router.push({
          path: '/production/project-create',
          query: {
            id: data.project_id,
          }
        });
      })
    },
  },
  components: {
    ProjectOp,
    taskOp,
    unpackOp
  }
};
</script>
<style lang="scss" scoped>
.project-list-main{
  margin: 20px;
  padding: 20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 12px 0px rgba(189,189,189,0.4100);
  border-radius: 4px;

  .margin-bottom-10{
    margin-bottom: 20px;
  }
  .top{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
.preview-modal .ivu-modal {
  width: 80% !important;
  max-width: 1440px;
}

.select-category-modal {
  .ivu-modal {
    width: 75% !important;
    min-width: 480px;
    max-width: 960px;
  }

  .category-list {
    padding: 0 15px;

    .category-list-top{
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      >div{
        width: 80px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border:1px solid #dcdee2;
        border-radius: 4px;
        cursor: pointer;
        margin-right: 20px;
        font-size: 14px;
      }
      >div:hover,
      >div.active{
        color:#2db7f5;
        border:1px solid #2db7f5;
      }
    }
    .category-list-cont{
      margin-top: 20px;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;

      >div{
        padding: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
        width: 200px;
        height: 220px;
        border:1px solid #dcdee2;
        border-radius: 4px;
        position: relative;

        .tit{
          margin-bottom: 10px;
          font-size: 14px;
          text-align: center;
        }
        .desc{
          line-height: 24px;
        }
        .btn{
          position: absolute;
          width: 160px;
          left: 20px;
          bottom: 20px;
        }
      }

    }
  }

  //.category-item {
  //  text-align: center;
  //  min-width: 200px;
  //  min-height: 160px;
  //  border: 1px solid #d7d7d7;
  //  border-radius: 10px;
  //  margin: 10px;
  //  padding: 20px 0;
  //  cursor: pointer;
  //
  //  &:hover {
  //    background-color: rgba(24, 144, 255, 0.2);
  //    border-color: rgba(24, 144, 255, 0.2);
  //  }
  //
  //  .category-name {
  //    font-size: 14px;
  //    color: rgba(0, 0, 0, 0.6);
  //    margin-top: 20px;
  //    font-weight: 600;
  //  }
  //}
}
</style>
<style>
/* 样式只针对火狐浏览器 */
@-moz-document url-prefix(){
  .ivu-table-filter-list .ivu-table-filter-select-item {
    padding: 7px 28px 7px 16px;
    white-space: nowrap;
  }
}
</style>

